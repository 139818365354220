import React from "react";
import { useField, useFormikContext } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import styled from "@emotion/styled";

const CssCustomField = styled(DatePicker)({
  "& label.Mui-focused": {
    color: "#575757",
    fontSize: 14,
  },

  "& label": {
    color: "#575757",
    fontSize: 14,
  },
  "& input": {
    color: "#575757",
    fontSize: 14,
  },
  "& .MuiSvgIcon-root": {
    color: "#575757",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#575757",
  },
  "& .MuiFormHelperText-root": {
    color: "#d32f2f",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    "& fieldset": {
      borderColor: "#d32f2f",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#575757",
    },
    "&:hover fieldset": {
      borderColor: "#575757",
    },
    "&:focused fieldset": {
      borderColor: "#575757",
    },
    "&:after fieldset": {
      borderColor: "#575757",
    },
    "&:before fieldset": {
      borderColor: "#575757",
    },
  },
});

function DateField({
  attribute: {
    name,
    type,
    label,
    disabled,
    variant = "outlined",
    visible = false,
    width,
  },
  loading,
}) {
  const [field, meta] = useField(name);
  const { setFieldValue, setFieldTouched, values } = useFormikContext();

  const disabledWrapper = () => {
    if (loading) {
      return true;
    }

    return typeof disabled === "function" ? disabled(values) : disabled;
  };

  return (
    <div className={`${visible ? "hidden" : "flex"} ${width}`}>
      {!loading && (
        <CssCustomField
          {...field}
          label={label}
          format="L"
          ampm={false}
          desktopModeMediaQuery="@media (min-width: 0px)"
          visible={visible}
          onChange={(date) => {
            setFieldValue(name, date);
            setFieldTouched(name, true, false);
          }}
          slotProps={{
            textField: {
              error: !!meta.error,
              disabled: disabledWrapper(),
              fullWidth: true,
              label,
              variant,
              helperText:
                meta.error ===
                "date must be a `date` type, but the final value was: `Invalid Date` (cast from the value `null`)."
                  ? "Data Inválida"
                  : meta.error,
            },
          }}
        />
      )}
    </div>
  );
}

export default DateField;
