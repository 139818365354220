import {
  CheckCircle,
  Clock,
  ClockCounterClockwise,
  HandPalm,
  Lock,
  PaperPlaneTilt,
  XCircle,
} from "@phosphor-icons/react";
import { UilWhatsapp, UilGift, UilTagAlt } from "@iconscout/react-unicons";
import React from "react";
import moment from "moment";
import { useAuth } from "../../../hooks/use-auth";
import ADateText from "../../../components/a-date-text";
import { usePermission } from "../../../hooks/use-permission";
import { useContextValue } from "../../../context";
import { useCompany } from "../../../hooks/use-company";

function getStatus(data) {
  return data?.order?.type !== "BLOCKED" && !data?.canceledAt
    ? "border-green-600"
    : data?.order?.type === "BLOCKED"
    ? "border-primary"
    : "border-red-600 opacity-50";
}

function getDuration(data) {
  const min = data?.order?.orderService?.map(({ duration }) => duration);
  return min?.reduce((acc, currentValue) => acc + currentValue, 0);
}

const separarString = (str) => {
  const parts = str.split(" (");
  const service = parts[0];
  const name = parts[1]?.slice(0, -1);
  return { service, name };
};

function serviceName(orderServices) {
  const name = orderServices?.map(({ name }) => separarString(name));

  const format = name?.reduce((acc, currentValue) => {
    const result = `${acc} ${acc && "-"} ${currentValue.service}`;
    return result.trim();
  }, "");

  return format && `${format} ${name[0]?.name ? `(${name[0]?.name})` : ""}`;
}

function sendMessage({ data, company }) {
  const link = `https://ageendae.com.br/${company?.schema}/appointment/${data?.id}`;

  const messageToday = `Olá *${data?.userClient?.name?.trim()}*,
  seu agendamento *${serviceName(data?.order?.orderService)?.trim()}*
  na empresa *${company?.name?.trim()}* é 
  *hoje* às *${moment(data?.date).format("HH:mm")}*.
  
  ⬇️⬇️ Link para *CANCELAR* ⬇️⬇️
  
  ${link}
  `;
  const messageTomorrow = `Olá *${data?.userClient?.name?.trim()}*,
  seu agendamento *${serviceName(data?.order?.orderService)?.trim()}*
  na empresa *${company?.name?.trim()}* é
  *amanhã* às *${moment(data?.date).format("HH:mm")}*.
  
  ⬇️⬇️ Link para *CANCELAR* ⬇️⬇️
  
  ${link}
  `;

  const message = moment(data?.date).isSame(moment(), "day")
    ? messageToday
    : messageTomorrow;

  return message;
}

function getName(data) {
  return (
    <div className="flex gap-2">
      {data?.order?.orderService?.map((currentValue, index) => (
        <React.Fragment key={index}>
          {index > 0 && <span>-</span>}
          {currentValue.plan ? (
            <div className="flex gap-2 text-green-500">
              <UilTagAlt />
              <span>{currentValue?.service?.name || currentValue?.name}</span>
            </div>
          ) : (
            currentValue?.service?.name || currentValue?.name
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

function getNameClienteOrObservation(data, user) {
  if (data?.order?.observation && user?.id === data?.userClient?.id) {
    return data?.order?.observation;
  }

  return data?.userClient?.name;
}

export default function CardAppointment({
  data,
  onDiscart,
  onDiscartRecurrence,
  client,
}) {
  const { selDate } = useContextValue();
  const { user } = useAuth();
  const showIconWpp = usePermission(5);
  const { company } = useCompany();

  return (
    <div
      className={`flex flex-col border-l-4 ${getStatus(
        data
      )} bg-white w-full rounded-md p-4 gap-4`}
    >
      <div className="flex items-center justify-between w-full pb-4 border-b">
        <div className="flex flex-col justify-center w-full gap-2">
          <div className="flex items-center gap-2">
            <strong>
              <Clock size={20} weight="bold" />
            </strong>
            <strong>
              <ADateText date={data?.date} format="HH:mm" />
            </strong>
            <strong>-</strong>
            <strong>
              <ADateText
                date={data?.date}
                format="HH:mm"
                add={getDuration(data)}
                addType="minute"
              />
            </strong>
            {data?.userClient?.phone && (
              <div className="flex items-center gap-2">
                <strong
                  className={`${data?.notification} ? text-green-700 : text-red-700`}
                >
                  <PaperPlaneTilt size={20} />
                </strong>
              </div>
            )}
          </div>
          <span className="text-slate-400">{getName(data)}</span>
        </div>
        <div className="flex flex-col gap-4">
          {user?.manager && !data?.canceledAt && (
            <button onClick={onDiscart} className="text-red-700">
              <HandPalm size={20} />
            </button>
          )}
          {!!onDiscart &&
            data?.active &&
            !data?.canceledAt &&
            !user?.manager && (
              <button onClick={onDiscart} className="text-red-700">
                <HandPalm size={20} />
              </button>
            )}
          {data?.recurrence && !data?.canceledAt && (
            <button onClick={onDiscartRecurrence}>
              <ClockCounterClockwise
                size={20}
                color={data?.countRecurrence <= 4 && "#e80505"}
              />
            </button>
          )}
        </div>
      </div>
      <div className="flex items-center justify-between w-full gap-4">
        <div className="w-14">
          <img
            src="/./ios/180.png"
            alt=""
            width={50}
            className="object-contain"
          />
        </div>
        <div className="flex flex-col w-full">
          {!client ? (
            <div className="flex items-center gap-4">
              <strong className="text-primary">
                {getNameClienteOrObservation(data, user)}
              </strong>
              {data?.userClient?.phone &&
                user?.id !== data?.userClient?.id &&
                showIconWpp && (
                  <button
                    onClick={() => {
                      if (
                        navigator.userAgent.match(/Android/i) ||
                        navigator.userAgent.match(/webOS/i) ||
                        navigator.userAgent.match(/iPhone/i) ||
                        navigator.userAgent.match(/iPad/i) ||
                        navigator.userAgent.match(/iPod/i) ||
                        navigator.userAgent.match(/BlackBerry/i) ||
                        navigator.userAgent.match(/Windows Phone/i)
                      ) {
                        window.open(
                          `whatsapp://send?phone=${
                            data?.userClient?.phone
                          }&text=${sendMessage({ data, company })}`
                        );
                      } else {
                        window.open(
                          `https://api.whatsapp.com/send?phone=${
                            data?.userClient?.phone
                          }&text=${sendMessage({ data, company })}`
                        );
                      }
                    }}
                  >
                    <UilWhatsapp className="text-green-700" size={20} />
                  </button>
                )}
              {data?.userClient?.birthdate &&
                moment(data?.userClient?.birthdate).format("DD-MM") ===
                  moment(selDate, "YYYY-MM-DD").format("DD-MM") && (
                  <strong className="text-green-500">
                    <UilGift />
                  </strong>
                )}
            </div>
          ) : (
            <strong className="text-primary">{data?.company?.name}</strong>
          )}
          <span className="text-slate-400">{data?.employee?.name}</span>
        </div>
        <div className="flex flex-col gap-4">
          {!data?.canceledAt &&
            !data?.active &&
            data?.order?.type !== "BLOCKED" &&
            moment().isSameOrAfter(
              moment(data?.date).add(getDuration(data), "minute")
            ) && <CheckCircle size={20} className="text-green-700" />}
          {data?.canceledAt && <XCircle size={20} className="text-red-700" />}
          {data?.order?.type === "BLOCKED" && (
            <Lock size={20} className="text-primary" />
          )}
        </div>
      </div>
    </div>
  );
}
