import { CheckCircle, Trash, XCircle } from "@phosphor-icons/react";
import React from "react";
import { Modal } from "rsuite";
import moment from "moment";

export default function AlertModal({
  open,
  title,
  message,
  confirLabel,
  cancelLabel,
  confirmAction,
  cancelAction,
  options,
  onDiscartAppointment,
  icon,
  loading,
  selected,
}) {
  return (
    <Modal open={open} size="xs" backdrop="static">
      <header>
        <strong>{title}</strong>
      </header>
      {options?.length > 0 ? (
        <div className="flex flex-col gap-2 py-4 overflow-y-auto h-4/5 max-h-[calc(100vh-200px)]">
          {options
            ?.sort((a, b) => moment(b?.date) - moment(a?.date))
            ?.map((res, i) => (
              <div
                key={i}
                className="flex items-center justify-between w-full p-2 rounded-md bg-secondary"
              >
                <div className="space-x-2">
                  <strong>Dia:</strong>
                  <span>{moment(res?.date).format("DD-MM-YYYY")}</span>
                </div>
                <div className="space-x-2">
                  <strong>Horário:</strong>
                  <span>{moment(res?.date).format("HH:mm")}</span>
                </div>
                <strong>
                  {res.scheduled && (
                    <CheckCircle
                      size={20}
                      className="text-green-700"
                      weight="fill"
                    />
                  )}
                  {!res.scheduled && !res?.id && (
                    <XCircle size={20} className="text-red-700" weight="fill" />
                  )}
                  {res?.id && (
                    <button onClick={() => onDiscartAppointment(res)}>
                      {loading && selected?.id === res.id ? (
                        icon
                      ) : (
                        <Trash
                          size={20}
                          className="text-red-700"
                          weight="fill"
                        />
                      )}
                    </button>
                  )}
                </strong>
              </div>
            ))}
        </div>
      ) : (
        <main className="py-4">
          <span>{message}</span>
        </main>
      )}
      <footer className="flex items-center justify-end gap-4">
        {confirLabel && (
          <button
            onClick={confirmAction}
            className="px-4 py-1 text-white rounded-md bg-primary"
          >
            {confirLabel}
          </button>
        )}

        {cancelLabel && (
          <button
            onClick={cancelAction}
            className="px-4 py-1 text-white rounded-md bg-primary"
          >
            {cancelLabel}
          </button>
        )}
      </footer>
    </Modal>
  );
}
